import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Link from 'gatsby-plugin-transition-link/AniLink';

import SvgLogo from '../SVGs/logo';
import SvgBehance from '../SVGs/Behance';
import SvgDribble from '../SVGs/Dribble';
import SvgInsta from '../SVGs/Insta';

const pages = [
  { to: '/projects', title: 'Projects', eventKey: 'projects', button: false },
  { to: '/process', title: 'Process', eventKey: 'process', button: false },
  { to: '/services', title: 'Services', eventKey: 'services', button: false },
  { to: '/about', title: 'About', eventKey: 'about', button: false },
  { to: '/careers', title: 'Careers', eventKey: 'careers', button: false },
];

const Footer = ({ pageInfo }) => {
  return (
    <Row noGutters>
      <Col className="footer-col">
        <footer className="h-auto py-3" id="footer">
          <Container>
            <Row noGutters>
              <Col xs={12} lg={6} className="align-items-center d-flex">
                <Row noGutters className="w-100 pb-3 pt-2  justify-content-between justify-content-lg-start">
                  <div className="d-flex align-items-center">
                    <Link paintDrip hex="#000000" to="/projects" className="link-no-style pr-3 d-block">
                      <SvgLogo width="225" className="svg-logo-footer" fill="#fff" />
                    </Link>
                  </div>
                  <div className="d-flex align-items-center">
                    <a href="https://www.behance.net/7thpillar" target="_blank" rel="noreferrer" className="custom-button mr-3 bg-light small">
                      <SvgBehance style={{ width: '1.5rem', height: '1.5rem' }} />
                    </a>
                    <a href="https://www.instagram.com/7thpillarinfotech/" target="_blank" rel="noreferrer" className="custom-button mr-3 bg-light small">
                      <SvgInsta style={{ width: '1.5rem', height: '1.5rem' }} />
                    </a>
                    <a href="https://dribbble.com/7thpillar" target="_blank" rel="noreferrer" className="custom-button mr-3 bg-light small">
                      <SvgDribble style={{ width: '1.5rem', height: '1.5rem' }} />
                    </a>
                  </div>
                </Row>
              </Col>
              <Col xs={12} lg={6} className="pb-3 pb-lg-1 align-items-center justify-content-end d-flex justify-content-between p-2 footer-links">
                {pages.map((item) => (
                  <Link paintDrip hex="#000000" to={item.to} activeKey={pageInfo && pageInfo.pageName} className={`link-no-style page-button ${pageInfo && pageInfo.pageName === item.eventKey ? 'active' : ''}`}>
                    {item.title}
                  </Link>
                ))}
              </Col>
            </Row>
          </Container>
        </footer>
      </Col>
    </Row>
  );
};

export default Footer;
