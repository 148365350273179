import React from 'react';

const SvgBehance = (props) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" {...props}>
    <g id="Group_429" data-name="Group 429">
      <path
        id="Path_1036"
        data-name="Path 1036"
        d="M31.64,35.51a9.77,9.77,0,0,0,3.11-2.29,8.83,8.83,0,0,0,1.89-5.93,10.15,10.15,0,0,0-1.86-6.15Q31.66,17,24.2,16.85H4.47V57.1H22.86a28.63,28.63,0,0,0,5.77-.54,11.42,11.42,0,0,0,4.61-2,11.18,11.18,0,0,0,2.88-3.17A11.47,11.47,0,0,0,37.94,45a10.53,10.53,0,0,0-1.58-5.89A9.19,9.19,0,0,0,31.64,35.51Zm-19-11.67h8.89a15.8,15.8,0,0,1,4.83.63,3.63,3.63,0,0,1,2.19,3.73,3.79,3.79,0,0,1-1.67,3.53,8.3,8.3,0,0,1-4.31,1H12.6ZM26.65,49.41a9.6,9.6,0,0,1-4.14.71H12.6V39.39H22.66a10.34,10.34,0,0,1,4.08.68,4.32,4.32,0,0,1,2.59,4.32A5.06,5.06,0,0,1,26.65,49.41Z"
      />
      <rect id="Rectangle_85" data-name="Rectangle 85" x="46.67" y="18.73" width="17.49" height="5.01" />
      <path
        id="Path_1037"
        data-name="Path 1037"
        d="M70.23,38.5a14.31,14.31,0,0,0-2.4-6.15A12.11,12.11,0,0,0,62.62,28a17.15,17.15,0,0,0-7.1-1.4,14.73,14.73,0,0,0-10.79,4.13,16,16,0,0,0-4.15,11.87q0,8.25,4.61,11.92a16.62,16.62,0,0,0,10.63,3.66,14.77,14.77,0,0,0,11.35-4.34,9.71,9.71,0,0,0,2.92-5.38H62a6.57,6.57,0,0,1-1.62,2.05A6.76,6.76,0,0,1,56,51.82a8.17,8.17,0,0,1-4.35-1.12,7.11,7.11,0,0,1-3.16-6.29h22A37.68,37.68,0,0,0,70.23,38.5Zm-21.52.78a8.1,8.1,0,0,1,2.1-4.61A6.32,6.32,0,0,1,55.52,33a7,7,0,0,1,4.69,1.6,6.48,6.48,0,0,1,2.1,4.71Z"
      />
    </g>
  </svg>
);

export default SvgBehance;
