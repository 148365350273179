import React from 'react';

const SvgLogo = (props) => (
  <svg id="pillar_logo" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.5 80">
    <g>
      <path d="M79.37,11.43v4.83L69.84,39.13H63.19L72.84,17H61V11.43Z" />
      <path d="M83.67,23.33H81.08v-5.4h2.59V12.76h6.5v5.17h4.25v5.4H90.17v8.51c0,1.25.53,1.78,2,1.78h2.32v5.51H91.15c-4.4,0-7.48-1.86-7.48-7.37Z" />
      <path d="M98.07,11h6.5v9.76a7.83,7.83,0,0,1,6.53-3.08c4.83,0,8.06,3.35,8.06,9.05V39.13H112.7V27.62c0-2.89-1.6-4.48-4.07-4.48s-4.06,1.59-4.06,4.48V39.13h-6.5Z" />
      <path d="M142.26,29.56h-4v9.57h-6.49V12.46h10.52c6.54,0,9.8,3.69,9.8,8.59C152.06,25.46,149.06,29.56,142.26,29.56Zm-.49-5.17c2.58,0,3.68-1.29,3.68-3.34s-1.1-3.35-3.68-3.35h-3.54v6.69Z" />
      <path d="M154.84,12.23a3.87,3.87,0,1,1,3.88,3.5A3.59,3.59,0,0,1,154.84,12.23Zm.61,5.7H162v21.2h-6.5Z" />
      <path d="M166.66,11h6.5V39.13h-6.5Z" />
      <path d="M177.87,11h6.5V39.13h-6.5Z" />
      <path d="M197.13,17.63a7.38,7.38,0,0,1,6.54,3.3v-3h6.5v21.2h-6.5v-3a7.5,7.5,0,0,1-6.57,3.31c-5.17,0-9.31-4.26-9.31-10.95S191.93,17.63,197.13,17.63Zm1.9,5.66c-2.43,0-4.63,1.82-4.63,5.2s2.2,5.29,4.63,5.29,4.64-1.87,4.64-5.25S201.5,23.29,199,23.29Z" />
      <path d="M221.38,39.13h-6.5V17.93h6.5v3.54A7.89,7.89,0,0,1,228,17.7v6.88h-1.79c-3.08,0-4.86,1.06-4.86,4.71Z" />
    </g>
    <g>
      <path d="M62.06,49.67h3.19V69.13H62.06Z" />
      <path d="M80.4,60.51c0-2.83-1.54-4.29-3.87-4.29s-3.89,1.46-3.89,4.29v8.62H69.45V53.7h3.19v1.77a6,6,0,0,1,4.59-2c3.62,0,6.33,2.27,6.33,6.58v9.1H80.4Z" />
      <path d="M88,56.31H86.19V53.7H88V52.61c0-3.55,1.82-5.09,5.85-5.09v2.66c-2,0-2.63.67-2.63,2.43V53.7h2.86v2.61H91.23V69.13H88Z" />
      <path d="M103.61,69.38c-4.4,0-7.73-3.13-7.73-8a7.87,7.87,0,1,1,15.74,0A7.73,7.73,0,0,1,103.61,69.38Zm0-2.77c2.38,0,4.73-1.71,4.73-5.21s-2.27-5.18-4.65-5.18-4.56,1.68-4.56,5.18S101.2,66.61,103.61,66.61Z" />
      <path d="M115.28,56.31h-1.82V53.7h1.82V49.87h3.22V53.7h3.76v2.61H118.5v8.54c0,1.15.45,1.62,1.8,1.62h2v2.66h-2.52c-2.75,0-4.46-1.15-4.46-4.28Z" />
      <path d="M131.83,69.38c-4.42,0-7.7-3.13-7.7-8s3.14-7.95,7.7-7.95A7.2,7.2,0,0,1,139.34,61a9.23,9.23,0,0,1-.12,1.57H127.44a4.24,4.24,0,0,0,4.39,4.09,3.75,3.75,0,0,0,3.64-2.16h3.45A7.05,7.05,0,0,1,131.83,69.38ZM127.46,60H136a4,4,0,0,0-4.31-3.89A4.21,4.21,0,0,0,127.46,60Z" />
      <path d="M149,53.45c3.78,0,6.24,1.88,7.11,5.13h-3.44A3.61,3.61,0,0,0,149,56.14c-2.58,0-4.29,1.9-4.29,5.26s1.71,5.3,4.29,5.3a3.53,3.53,0,0,0,3.67-2.44h3.44A6.93,6.93,0,0,1,149,69.38c-4.43,0-7.56-3.13-7.56-8S144.54,53.45,149,53.45Z" />
      <path d="M159.27,48.41h3.19V55.5a6.12,6.12,0,0,1,4.79-2c3.45,0,6.13,2.27,6.13,6.58v9.1h-3.16V60.51c0-2.83-1.54-4.29-3.86-4.29s-3.9,1.46-3.9,4.29v8.62h-3.19Z" />
    </g>
    <path d="M2.87,33.4V25.3h30.5L.47,71.3v-15l16.7-22.9Z" />
    <path d="M4.07,17.1h34.2l-3.6,4.3H4.07Z" />
    <path d="M5.07,8.9h39L40,13.2H5.07Z" />
  </svg>
);

export default SvgLogo;
