import React from 'react';

const SvgCircles = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 620 620">
    <g id="Group_89" data-name="Group 89" transform="translate(0.44 0.44)" opacity="0.253">
      <circle id="Ellipse_17" data-name="Ellipse 17" cx="309.775" cy="309.775" r="309.775" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_18" data-name="Ellipse 18" cx="282.321" cy="282.321" r="282.321" transform="translate(27.454 27.454)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_19" data-name="Ellipse 19" cx="254.867" cy="254.867" r="254.867" transform="translate(54.908 54.908)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_20" data-name="Ellipse 20" cx="227.413" cy="227.413" r="227.413" transform="translate(82.362 82.361)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_21" data-name="Ellipse 21" cx="199.959" cy="199.959" r="199.959" transform="translate(109.815 109.815)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_22" data-name="Ellipse 22" cx="172.506" cy="172.506" r="172.506" transform="translate(137.269 137.269)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_23" data-name="Ellipse 23" cx="145.052" cy="145.052" r="145.052" transform="translate(164.723 164.723)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_24" data-name="Ellipse 24" cx="117.598" cy="117.598" r="117.598" transform="translate(192.177 192.177)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_25" data-name="Ellipse 25" cx="90.144" cy="90.144" r="90.144" transform="translate(219.631 219.631)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_26" data-name="Ellipse 26" cx="62.69" cy="62.69" r="62.69" transform="translate(247.085 247.084)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
      <circle id="Ellipse_27" data-name="Ellipse 27" cx="35.236" cy="35.236" r="35.236" transform="translate(274.538 274.538)" fill="none" stroke="#434343" strokeMiterlimit="10" strokeWidth="0.88" />
    </g>
  </svg>
);

export default SvgCircles;
