import React from 'react';

const SvgInsta = (props) => (
  <svg id="Layer_4" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" {...props}>
    <path
      id="Path_1038"
      data-name="Path 1038"
      d="M70.27,23.92a24.06,24.06,0,0,0-1.53-8A16.94,16.94,0,0,0,59.1,6.28a24.53,24.53,0,0,0-8-1.53c-3.52-.16-4.64-.2-13.59-.2s-10.07,0-13.59.19a24,24,0,0,0-8,1.53,16.11,16.11,0,0,0-5.84,3.81,16.36,16.36,0,0,0-3.82,5.84,24.26,24.26,0,0,0-1.52,8c-.16,3.52-.2,4.64-.2,13.59s0,10.07.19,13.58a24,24,0,0,0,1.53,8,16.92,16.92,0,0,0,9.65,9.65,24,24,0,0,0,8,1.53c3.52.15,4.64.19,13.59.19s10.06,0,13.58-.19a24.06,24.06,0,0,0,8-1.53,16.86,16.86,0,0,0,9.65-9.65,24,24,0,0,0,1.53-8c.15-3.52.19-4.64.19-13.58S70.43,27.44,70.27,23.92ZM64.34,50.83a18.11,18.11,0,0,1-1.13,6.1A11,11,0,0,1,57,63.2a18.4,18.4,0,0,1-6.1,1.13c-3.48.15-4.51.19-13.31.19s-9.85,0-13.32-.19a18.15,18.15,0,0,1-6.1-1.13,10.35,10.35,0,0,1-3.79-2.46A10.3,10.3,0,0,1,11.85,57a18.16,18.16,0,0,1-1.14-6.1c-.15-3.48-.19-4.51-.19-13.32s0-9.84.19-13.31a18.16,18.16,0,0,1,1.14-6.1,10,10,0,0,1,2.47-3.79,10.31,10.31,0,0,1,3.78-2.46,18.46,18.46,0,0,1,6.11-1.13c3.48-.15,4.51-.19,13.31-.19s9.85,0,13.31.19a18.21,18.21,0,0,1,6.11,1.13,10.23,10.23,0,0,1,3.78,2.46,10.35,10.35,0,0,1,2.46,3.79,18.16,18.16,0,0,1,1.14,6.1c.15,3.48.19,4.51.19,13.31S64.49,47.35,64.34,50.83Z"
    />
    <path id="Path_1039" data-name="Path 1039" d="M37.51,20.58A16.93,16.93,0,1,0,54.44,37.51,16.93,16.93,0,0,0,37.51,20.58Zm0,27.91a11,11,0,1,1,11-11A11,11,0,0,1,37.51,48.49Z" />
    <path id="Path_1040" data-name="Path 1040" d="M59.06,19.91a4,4,0,1,1-4-3.95A4,4,0,0,1,59.06,19.91Z" />
  </svg>
);

export default SvgInsta;
