import React from 'react';
import { Jumbotron, Container } from 'react-bootstrap';
import Link from 'gatsby-plugin-transition-link/AniLink';

const UpperFooter = () => {
  return (
    <Jumbotron fluid className="mb-0" style={{ backgroundColor: '#F7F9FF' }}>
      <Container>
        <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: 'column' }}>
          <h2 className="text-center px-3">Let’s build something great together</h2>
          <h6 className="text-center py-3" style={{ maxWidth: 600 }}>
            We would love to hear from you and be a part of your journey by bringing our skills and passion to build digital products to help grow your business.
          </h6>

          <Link paintDrip hex="#003bff" to="/getaquote" className="link-no-style">
            <button type="button" className="custom-button">
              GET A QUOTE
            </button>
          </Link>
        </div>
      </Container>
    </Jumbotron>
  );
};

export default UpperFooter;
