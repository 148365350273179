import React from 'react';

const SvgDribble = (props) => (
  <svg id="Layer_2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" {...props}>
    <path
      id="basketball"
      d="M37.5,4.36A33.14,33.14,0,1,0,70.64,37.5h0A33.21,33.21,0,0,0,37.5,4.36ZM60,18.79A29.16,29.16,0,0,1,66.75,37a41.56,41.56,0,0,0-16.81-.22,46.49,46.49,0,0,0-4.57,1.07c-.51-1.38-1-2.74-1.6-4.09s-1.2-2.73-1.84-4.06C48.91,27,56.49,23.07,60,18.79ZM37.5,8.24A29.18,29.18,0,0,1,57.27,16c-2,2.77-7.82,6.8-17.11,10.28A84.6,84.6,0,0,0,28.52,9.65,29.46,29.46,0,0,1,37.5,8.24Zm-12.81,3A80.33,80.33,0,0,1,36.45,27.52,69.57,69.57,0,0,1,9,31.11a29.34,29.34,0,0,1,15.73-19.9ZM8.24,37.5q0-1.29.12-2.55a53.94,53.94,0,0,0,5.54.28A79.21,79.21,0,0,0,38.26,31a87.24,87.24,0,0,1,3.43,8,54.25,54.25,0,0,0-9.38,4.42A34.76,34.76,0,0,0,17.5,58.84,29.2,29.2,0,0,1,8.24,37.5ZM37.5,66.76a29.17,29.17,0,0,1-16.81-5.34c1.47-5,6.47-10.38,13.61-14.63A49.5,49.5,0,0,1,43,42.73a90.91,90.91,0,0,1,4.1,22.42A29,29,0,0,1,37.5,66.76Zm13.35-3.23a93.9,93.9,0,0,0-4.2-22A38.93,38.93,0,0,1,66.55,41,29.29,29.29,0,0,1,50.84,63.53Z"
    />
  </svg>
);

export default SvgDribble;
