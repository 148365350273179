/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { TransitionState } from 'gatsby-plugin-transition-link';

import Footer from './footer';
import UpperFooter from './upper_footer';
import SvgServices from '../SVGs/Services';
import SvgCircles from '../SVGs/Circles';
import Header from './header';

const windowGlobal = typeof window !== 'undefined' && window;

const CommonBanner = ({ title, description }) => {
  return (
    <>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', minHeight: 350 }}>
        <div
          className="d-none d-lg-block"
          style={{
            height: 350,
            width: 350,
            position: 'absolute',
            left: 0,
            pointerEvents: 'none',
            top: -50,
            zIndex: 1000,
            opacity: 1,
            overflow: 'hidden',
          }}
        >
          <SvgCircles
            style={{
              position: 'absolute',
              left: -150,
              height: '100%',
            }}
          />
        </div>
        <Container>
          <Row className="justify-content-center py-3 pt-5">
            <Col className="text-dark text-center">
              <h1 className="w-100 m-auto pb-3" style={{ maxWidth: 600 }}>
                {title}
              </h1>
              <h6 className="w-100 m-auto" style={{ maxWidth: 600 }}>
                {description}
              </h6>
            </Col>
          </Row>
        </Container>
        <div
          className="d-none d-lg-block"
          style={{
            pointerEvents: 'none',
            height: 350,
            width: 175,
            position: 'absolute',
            right: 0,
            top: -250,
            zIndex: 100,
            opacity: 0.2,
            overflow: 'hidden',
          }}
        >
          <SvgServices
            style={{
              position: 'absolute',
              right: -175,
              width: 350,
              height: 350,
            }}
          />
        </div>
      </div>
    </>
  );
};

const Layout = ({ children, Banner, title, description, home, pageInfo, noUpperFooter, noLayoutbanner }) => {
  const tlEdges = windowGlobal && windowGlobal.document && windowGlobal.document.getElementsByClassName('tl-edges');
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <TransitionState>
          {({ transitionStatus }) => {
            if (transitionStatus === 'exiting') {
              if (tlEdges && tlEdges.length && tlEdges[0].className && tlEdges[0].className.includes('overflow-visible')) {
                tlEdges[0].classList.remove('overflow-visible');
              }
            }

            return (
              <>
                <Header pageInfo={pageInfo} siteTitle={'7th Pillar'} />
                {home ? (
                  <>
                    {Banner}
                    <main className="py-5 bg-black">{children}</main>
                  </>
                ) : (
                  <>
                    {!noLayoutbanner ? <CommonBanner title={title} description={description} /> : null}
                    <Container fluid className="p-0 m-0 main h-100 w-100">
                      <main>{children}</main>
                    </Container>
                  </>
                )}
                {!noUpperFooter ? <UpperFooter /> : null}
                <Footer pageInfo={pageInfo} siteTitle={data.site.siteMetadata.title} />
              </>
            );
          }}
        </TransitionState>
      )}
    />
  );
};
export default Layout;
