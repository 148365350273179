import React from 'react';

const SvgServices = (props) => (
  <svg id="Layer_7" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <defs>
      <clipPath id="clip-path">
        <circle cx="-270" cy="288.09" r="236.92" style={{ fill: 'none' }} />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect x="-73.66" y="406.9" width="94.79" height="94.79" transform="translate(-328.93 114.49) rotate(-45)" style={{ fill: 'none' }} />
      </clipPath>
      <clipPath id="clip-path-3">
        <rect x="-73.66" y="272.85" width="94.79" height="94.79" transform="translate(-234.14 75.23) rotate(-45)" style={{ fill: 'none' }} />
      </clipPath>
      <clipPath id="clip-path-4">
        <rect x="-73.66" y="138.79" width="94.79" height="94.79" transform="translate(-139.35 35.96) rotate(-45)" style={{ fill: 'none' }} />
      </clipPath>
      <clipPath id="clip-path-5">
        <rect x="-73.66" y="4.73" width="94.79" height="94.79" transform="translate(-44.55 -3.31) rotate(-45)" style={{ fill: 'none' }} />
      </clipPath>
    </defs>

    <g id="Group_225" data-name="Group 225">
      <path id="Path_604" data-name="Path 604" d="M196.57,244.33a4.73,4.73,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605" data-name="Path 605" d="M230.09,210.81a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.09,210.81Z" />
      <path id="Path_606" data-name="Path 606" d="M230.08,277.84a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,230.08,277.84Z" />
      <path id="Path_607" data-name="Path 607" d="M263.6,244.32a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,263.6,244.32Z" />
      <path id="Path_608" data-name="Path 608" d="M263.6,210.81a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,210.81Z" />
      <path id="Path_609" data-name="Path 609" d="M230.08,244.33a4.74,4.74,0,1,0,6.71,0h0a4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_610" data-name="Path 610" d="M297.12,244.33a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,244.33Z" />
      <path id="Path_611" data-name="Path 611" d="M263.6,277.84a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,277.84Z" />
    </g>
    <g id="Group_225-2" data-name="Group 225">
      <path id="Path_604-2" data-name="Path 604" d="M196.57,143.72a4.74,4.74,0,1,0,6.71,0,4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_605-2" data-name="Path 605" d="M230.09,110.2a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,230.09,110.2Z" />
      <path id="Path_606-2" data-name="Path 606" d="M230.08,177.23a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,230.08,177.23Z" />
      <path id="Path_607-2" data-name="Path 607" d="M263.6,143.71a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,263.6,143.71Z" />
      <path id="Path_608-2" data-name="Path 608" d="M263.6,110.2a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,110.2Z" />
      <path id="Path_609-2" data-name="Path 609" d="M230.08,143.72a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-2" data-name="Path 610" d="M297.12,143.72a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,143.72Z" />
      <path id="Path_611-2" data-name="Path 611" d="M263.6,177.23a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,177.23Z" />
    </g>
    <g id="Group_225-3" data-name="Group 225">
      <path id="Path_604-3" data-name="Path 604" d="M337.27,144.66a4.73,4.73,0,1,0,0,6.7,4.73,4.73,0,0,0,0-6.7Z" />
      <path id="Path_605-3" data-name="Path 605" d="M370.84,178.13a4.74,4.74,0,1,0,0,6.7h0A4.75,4.75,0,0,0,370.84,178.13Z" />
      <path id="Path_606-3" data-name="Path 606" d="M303.81,178.22a4.74,4.74,0,1,0,0,6.71h0A4.74,4.74,0,0,0,303.81,178.22Z" />
      <path id="Path_607-3" data-name="Path 607" d="M337.38,211.68a4.74,4.74,0,1,0,0,6.71h0A4.75,4.75,0,0,0,337.38,211.68Z" />
      <path id="Path_608-3" data-name="Path 608" d="M370.89,211.64a4.74,4.74,0,1,0,0,6.7h0A4.74,4.74,0,0,0,370.89,211.64Z" />
      <path id="Path_609-3" data-name="Path 609" d="M337.33,178.17a4.74,4.74,0,1,0,0,6.71h0a4.76,4.76,0,0,0,0-6.71Z" />
      <path id="Path_610-3" data-name="Path 610" d="M337.42,245.2a4.74,4.74,0,1,0,0,6.71h0A4.73,4.73,0,0,0,337.42,245.2Z" />
      <path id="Path_611-3" data-name="Path 611" d="M303.86,211.74a4.74,4.74,0,1,0,0,6.7h0A4.74,4.74,0,0,0,303.86,211.74Z" />
    </g>
    <g id="Group_225-4" data-name="Group 225">
      <path id="Path_604-4" data-name="Path 604" d="M170.28,143.85a4.73,4.73,0,1,0-.1,6.7,4.73,4.73,0,0,0,.1-6.7Z" />
      <path id="Path_605-4" data-name="Path 605" d="M203.32,177.82a4.74,4.74,0,1,0-.09,6.71h0A4.75,4.75,0,0,0,203.32,177.82Z" />
      <path id="Path_606-4" data-name="Path 606" d="M136.3,176.89a4.74,4.74,0,1,0-.09,6.7h0A4.74,4.74,0,0,0,136.3,176.89Z" />
      <path id="Path_607-4" data-name="Path 607" d="M169.35,210.86a4.75,4.75,0,1,0-.1,6.71h0A4.76,4.76,0,0,0,169.35,210.86Z" />
      <path id="Path_608-4" data-name="Path 608" d="M202.86,211.33a4.74,4.74,0,1,0-.1,6.71h0A4.74,4.74,0,0,0,202.86,211.33Z" />
      <path id="Path_609-4" data-name="Path 609" d="M169.81,177.35a4.74,4.74,0,1,0-.09,6.71h0a4.74,4.74,0,0,0,.09-6.7Z" />
      <path id="Path_610-4" data-name="Path 610" d="M168.88,244.38a4.74,4.74,0,1,0-.1,6.7h0A4.74,4.74,0,0,0,168.88,244.38Z" />
      <path id="Path_611-4" data-name="Path 611" d="M135.83,210.4a4.74,4.74,0,1,0-.09,6.7h0A4.74,4.74,0,0,0,135.83,210.4Z" />
    </g>
    <g id="Group_225-5" data-name="Group 225">
      <path id="Path_604-5" data-name="Path 604" d="M196.57,344.94a4.74,4.74,0,1,0,6.71,0,4.73,4.73,0,0,0-6.71,0Z" />
      <path id="Path_605-5" data-name="Path 605" d="M230.09,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.09,311.42Z" />
      <path id="Path_606-5" data-name="Path 606" d="M230.08,378.45a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,230.08,378.45Z" />
      <path id="Path_607-5" data-name="Path 607" d="M263.6,344.93a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,344.93Z" />
      <path id="Path_608-5" data-name="Path 608" d="M263.6,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,311.42Z" />
      <path id="Path_609-5" data-name="Path 609" d="M230.08,344.93a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-5" data-name="Path 610" d="M297.12,344.93a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,344.93Z" />
      <path id="Path_611-5" data-name="Path 611" d="M263.6,378.45a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,378.45Z" />
    </g>
    <g id="Group_225-6" data-name="Group 225">
      <path id="Path_604-6" data-name="Path 604" d="M330.72,277.84a4.73,4.73,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-6" data-name="Path 605" d="M364.23,244.32a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,364.23,244.32Z" />
      <path id="Path_606-6" data-name="Path 606" d="M364.23,311.35a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.23,311.35Z" />
      <path id="Path_607-6" data-name="Path 607" d="M397.74,277.83a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,397.74,277.83Z" />
      <path id="Path_608-6" data-name="Path 608" d="M397.74,244.32a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,244.32Z" />
      <path id="Path_609-6" data-name="Path 609" d="M364.23,277.84a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-6" data-name="Path 610" d="M431.26,277.84a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.26,277.84Z" />
      <path id="Path_611-6" data-name="Path 611" d="M397.74,311.35a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,311.35Z" />
    </g>
    <g id="Group_225-7" data-name="Group 225">
      <path id="Path_604-7" data-name="Path 604" d="M129.55,311.42a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-7" data-name="Path 605" d="M163.06,277.9a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,163.06,277.9Z" />
      <path id="Path_606-7" data-name="Path 606" d="M163.06,344.93a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,163.06,344.93Z" />
      <path id="Path_607-7" data-name="Path 607" d="M196.57,311.41a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,196.57,311.41Z" />
      <path id="Path_608-7" data-name="Path 608" d="M196.57,277.9a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,196.57,277.9Z" />
      <path id="Path_609-7" data-name="Path 609" d="M163.06,311.42a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-7" data-name="Path 610" d="M230.09,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.09,311.42Z" />
      <path id="Path_611-7" data-name="Path 611" d="M196.57,344.93a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,196.57,344.93Z" />
    </g>
    <g id="Group_225-8" data-name="Group 225">
      <path id="Path_604-8" data-name="Path 604" d="M263.6,311.42a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-8" data-name="Path 605" d="M297.11,277.91a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.11,277.91Z" />
      <path id="Path_606-8" data-name="Path 606" d="M297.11,344.93a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.11,344.93Z" />
      <path id="Path_607-8" data-name="Path 607" d="M330.62,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,311.42Z" />
      <path id="Path_608-8" data-name="Path 608" d="M330.62,277.9a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,330.62,277.9Z" />
      <path id="Path_609-8" data-name="Path 609" d="M297.11,311.42a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-8" data-name="Path 610" d="M364.14,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.14,311.42Z" />
      <path id="Path_611-8" data-name="Path 611" d="M330.62,344.93a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,330.62,344.93Z" />
    </g>
    <g id="Group_225-9" data-name="Group 225">
      <path id="Path_604-9" data-name="Path 604" d="M263.59,110.21a4.73,4.73,0,1,0,6.7,0,4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_605-9" data-name="Path 605" d="M297.1,76.69a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.1,76.69Z" />
      <path id="Path_606-9" data-name="Path 606" d="M297.1,143.72a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.1,143.72Z" />
      <path id="Path_607-9" data-name="Path 607" d="M330.61,110.2a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,330.61,110.2Z" />
      <path id="Path_608-9" data-name="Path 608" d="M330.62,76.69a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,76.69Z" />
      <path id="Path_609-9" data-name="Path 609" d="M297.1,110.2a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-9" data-name="Path 610" d="M364.13,110.2a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,364.13,110.2Z" />
      <path id="Path_611-9" data-name="Path 611" d="M330.62,143.72a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,143.72Z" />
    </g>
    <g id="Group_225-10" data-name="Group 225">
      <path id="Path_604-10" data-name="Path 604" d="M330.72,144.67a4.73,4.73,0,1,0,6.7,0,4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_605-10" data-name="Path 605" d="M364.23,111.15a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.23,111.15Z" />
      <path id="Path_606-10" data-name="Path 606" d="M364.23,178.18a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.23,178.18Z" />
      <path id="Path_607-10" data-name="Path 607" d="M397.74,144.66a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,144.66Z" />
      <path id="Path_608-10" data-name="Path 608" d="M397.74,111.15a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,111.15Z" />
      <path id="Path_609-10" data-name="Path 609" d="M364.23,144.66a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-10" data-name="Path 610" d="M431.26,144.66a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.26,144.66Z" />
      <path id="Path_611-10" data-name="Path 611" d="M397.74,178.18a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,178.18Z" />
    </g>
    <g id="Group_225-11" data-name="Group 225">
      <path id="Path_604-11" data-name="Path 604" d="M364.13,211.74a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-11" data-name="Path 605" d="M397.64,178.23a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.64,178.23Z" />
      <path id="Path_606-11" data-name="Path 606" d="M397.63,245.25a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.63,245.25Z" />
      <path id="Path_607-11" data-name="Path 607" d="M431.15,211.74a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.15,211.74Z" />
      <path id="Path_608-11" data-name="Path 608" d="M431.15,178.22a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,431.15,178.22Z" />
      <path id="Path_609-11" data-name="Path 609" d="M397.63,211.74a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-11" data-name="Path 610" d="M464.67,211.74a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,464.67,211.74Z" />
      <path id="Path_611-11" data-name="Path 611" d="M431.15,245.25a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,431.15,245.25Z" />
    </g>
    <g id="Group_225-12" data-name="Group 225">
      <path id="Path_604-12" data-name="Path 604" d="M330.72,311.42a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-12" data-name="Path 605" d="M364.23,277.91a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.23,277.91Z" />
      <path id="Path_606-12" data-name="Path 606" d="M364.23,344.93a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.23,344.93Z" />
      <path id="Path_607-12" data-name="Path 607" d="M397.74,311.42a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,311.42Z" />
      <path id="Path_608-12" data-name="Path 608" d="M397.74,277.9a4.74,4.74,0,1,0,6.71,0h0A4.77,4.77,0,0,0,397.74,277.9Z" />
      <path id="Path_609-12" data-name="Path 609" d="M364.23,311.42a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-12" data-name="Path 610" d="M431.26,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.26,311.42Z" />
      <path id="Path_611-12" data-name="Path 611" d="M397.74,344.94a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,397.74,344.94Z" />
    </g>
    <g id="Group_225-13" data-name="Group 225">
      <path id="Path_604-13" data-name="Path 604" d="M297.16,378.45a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-13" data-name="Path 605" d="M330.67,344.94a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.67,344.94Z" />
      <path id="Path_606-13" data-name="Path 606" d="M330.67,412a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.67,412Z" />
      <path id="Path_607-13" data-name="Path 607" d="M364.18,378.44a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,364.18,378.44Z" />
      <path id="Path_608-13" data-name="Path 608" d="M364.18,344.93a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,364.18,344.93Z" />
      <path id="Path_609-13" data-name="Path 609" d="M330.67,378.45a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-13" data-name="Path 610" d="M397.7,378.45a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.7,378.45Z" />
      <path id="Path_611-13" data-name="Path 611" d="M364.18,412a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,364.18,412Z" />
    </g>
    <g id="Group_225-14" data-name="Group 225">
      <path id="Path_604-14" data-name="Path 604" d="M230.08,378.45a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-14" data-name="Path 605" d="M263.59,344.94a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.59,344.94Z" />
      <path id="Path_606-14" data-name="Path 606" d="M263.59,412a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.59,412Z" />
      <path id="Path_607-14" data-name="Path 607" d="M297.1,378.45a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,297.1,378.45Z" />
      <path id="Path_608-14" data-name="Path 608" d="M297.1,344.93a4.74,4.74,0,1,0,6.71,0h0A4.77,4.77,0,0,0,297.1,344.93Z" />
      <path id="Path_609-14" data-name="Path 609" d="M263.59,378.45a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-14" data-name="Path 610" d="M330.62,378.45a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,378.45Z" />
      <path id="Path_611-14" data-name="Path 611" d="M297.1,412a4.74,4.74,0,1,0,6.71,0h0A4.77,4.77,0,0,0,297.1,412Z" />
    </g>
    <g id="Group_225-15" data-name="Group 225">
      <path id="Path_604-15" data-name="Path 604" d="M163.53,378.45a4.73,4.73,0,1,0,6.7,0,4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_605-15" data-name="Path 605" d="M197,344.93a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,197,344.93Z" />
      <path id="Path_606-15" data-name="Path 606" d="M197,412a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,197,412Z" />
      <path id="Path_607-15" data-name="Path 607" d="M230.55,378.44a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,230.55,378.44Z" />
      <path id="Path_608-15" data-name="Path 608" d="M230.56,344.93a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.56,344.93Z" />
      <path id="Path_609-15" data-name="Path 609" d="M197,378.44a4.74,4.74,0,1,0,6.71,0h0a4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_610-15" data-name="Path 610" d="M264.07,378.44a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,264.07,378.44Z" />
      <path id="Path_611-15" data-name="Path 611" d="M230.56,412a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.56,412Z" />
    </g>
    <g id="Group_225-16" data-name="Group 225">
      <path id="Path_604-16" data-name="Path 604" d="M136.35,244.33a4.74,4.74,0,1,0,0,6.7,4.74,4.74,0,0,0,0-6.7Z" />
      <path id="Path_605-16" data-name="Path 605" d="M169.81,277.9a4.74,4.74,0,1,0,0,6.7h0A4.74,4.74,0,0,0,169.81,277.9Z" />
      <path id="Path_606-16" data-name="Path 606" d="M102.78,277.78a4.74,4.74,0,1,0,0,6.71h0A4.75,4.75,0,0,0,102.78,277.78Z" />
      <path id="Path_607-16" data-name="Path 607" d="M136.24,311.35a4.74,4.74,0,1,0,0,6.71h0A4.76,4.76,0,0,0,136.24,311.35Z" />
      <path id="Path_608-16" data-name="Path 608" d="M169.75,311.41a4.74,4.74,0,1,0,0,6.71h0A4.76,4.76,0,0,0,169.75,311.41Z" />
      <path id="Path_609-16" data-name="Path 609" d="M136.29,277.84a4.74,4.74,0,1,0,0,6.71h0a4.74,4.74,0,0,0,0-6.7Z" />
      <path id="Path_610-16" data-name="Path 610" d="M136.18,344.87a4.74,4.74,0,1,0,0,6.71h0A4.74,4.74,0,0,0,136.18,344.87Z" />
      <path id="Path_611-16" data-name="Path 611" d="M102.72,311.3a4.74,4.74,0,1,0,0,6.7h0A4.75,4.75,0,0,0,102.72,311.3Z" />
    </g>
    <g id="Group_225-17" data-name="Group 225">
      <path id="Path_604-17" data-name="Path 604" d="M196.57,76.69a4.74,4.74,0,1,0,6.71,0,4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_605-17" data-name="Path 605" d="M230.09,43.17a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,230.09,43.17Z" />
      <path id="Path_606-17" data-name="Path 606" d="M230.08,110.2a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,230.08,110.2Z" />
      <path id="Path_607-17" data-name="Path 607" d="M263.6,76.68a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,263.6,76.68Z" />
      <path id="Path_608-17" data-name="Path 608" d="M263.6,43.17a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,43.17Z" />
      <path id="Path_609-17" data-name="Path 609" d="M230.08,76.69a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-17" data-name="Path 610" d="M297.12,76.69a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,76.69Z" />
      <path id="Path_611-17" data-name="Path 611" d="M263.6,110.2a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,110.2Z" />
    </g>
    <g id="Group_225-18" data-name="Group 225">
      <path id="Path_604-18" data-name="Path 604" d="M129.08,110.2a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-18" data-name="Path 605" d="M162.59,76.69a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,162.59,76.69Z" />
      <path id="Path_606-18" data-name="Path 606" d="M162.59,143.71a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,162.59,143.71Z" />
      <path id="Path_607-18" data-name="Path 607" d="M196.1,110.2a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,196.1,110.2Z" />
      <path id="Path_608-18" data-name="Path 608" d="M196.1,76.68a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,196.1,76.68Z" />
      <path id="Path_609-18" data-name="Path 609" d="M162.59,110.2a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-18" data-name="Path 610" d="M229.62,110.2a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,229.62,110.2Z" />
      <path id="Path_611-18" data-name="Path 611" d="M196.1,143.71a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,196.1,143.71Z" />
    </g>
    <g id="Group_225-19" data-name="Group 225">
      <path id="Path_604-19" data-name="Path 604" d="M28.53,210.81a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-19" data-name="Path 605" d="M62.05,177.3a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,62.05,177.3Z" />
      <path id="Path_606-19" data-name="Path 606" d="M62,244.32a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,62,244.32Z" />
      <path id="Path_607-19" data-name="Path 607" d="M95.56,210.81a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,95.56,210.81Z" />
      <path id="Path_608-19" data-name="Path 608" d="M95.56,177.29a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,95.56,177.29Z" />
      <path id="Path_609-19" data-name="Path 609" d="M62,210.81a4.74,4.74,0,1,0,6.71,0h0a4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_610-19" data-name="Path 610" d="M129.07,210.81a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,129.07,210.81Z" />
      <path id="Path_611-19" data-name="Path 611" d="M95.56,244.32a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,95.56,244.32Z" />
    </g>
    <g id="Group_225-20" data-name="Group 225">
      <path id="Path_604-20" data-name="Path 604" d="M28.53,143.71a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-20" data-name="Path 605" d="M62.05,110.2a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,62.05,110.2Z" />
      <path id="Path_606-20" data-name="Path 606" d="M62,177.22a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,62,177.22Z" />
      <path id="Path_607-20" data-name="Path 607" d="M95.56,143.71a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,95.56,143.71Z" />
      <path id="Path_608-20" data-name="Path 608" d="M95.56,110.19a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,95.56,110.19Z" />
      <path id="Path_609-20" data-name="Path 609" d="M62,143.71a4.74,4.74,0,1,0,6.71,0h0a4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_610-20" data-name="Path 610" d="M129.07,143.71a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,129.07,143.71Z" />
      <path id="Path_611-20" data-name="Path 611" d="M95.56,177.23a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,95.56,177.23Z" />
    </g>
    <g id="Group_225-21" data-name="Group 225">
      <path id="Path_604-21" data-name="Path 604" d="M-5,244.32A4.75,4.75,0,0,0-5,251a4.74,4.74,0,0,0,6.7,0,4.76,4.76,0,0,0,0-6.7,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-21" data-name="Path 605" d="M28.53,210.81a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,28.53,210.81Z" />
      <path id="Path_606-21" data-name="Path 606" d="M28.52,277.83a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,28.52,277.83Z" />
      <path id="Path_607-21" data-name="Path 607" d="M62,244.32a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,62,244.32Z" />
      <path id="Path_608-21" data-name="Path 608" d="M62,210.8a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,62,210.8Z" />
      <path id="Path_609-21" data-name="Path 609" d="M28.52,244.32a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-21" data-name="Path 610" d="M95.56,244.32a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,95.56,244.32Z" />
      <path id="Path_611-21" data-name="Path 611" d="M62,277.84a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,62,277.84Z" />
    </g>
    <g id="Group_225-22" data-name="Group 225">
      <path id="Path_605-22" data-name="Path 605" d="M28.53,277.91a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,28.53,277.91Z" />
      <path id="Path_606-22" data-name="Path 606" d="M28.52,344.93a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,28.52,344.93Z" />
      <path id="Path_607-22" data-name="Path 607" d="M62,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,62,311.42Z" />
      <path id="Path_608-22" data-name="Path 608" d="M62,277.9a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,62,277.9Z" />
      <path id="Path_609-22" data-name="Path 609" d="M28.52,311.42a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-22" data-name="Path 610" d="M95.56,311.42a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,95.56,311.42Z" />
      <path id="Path_611-22" data-name="Path 611" d="M62,344.94a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,62,344.94Z" />
    </g>
    <g id="Group_225-23" data-name="Group 225">
      <path id="Path_604-22" data-name="Path 604" d="M102.71,349.6a4.74,4.74,0,1,0,0,6.7,4.73,4.73,0,0,0,0-6.7Z" />
      <path id="Path_605-23" data-name="Path 605" d="M136.26,383.07a4.74,4.74,0,1,0,0,6.7h0A4.74,4.74,0,0,0,136.26,383.07Z" />
      <path id="Path_606-23" data-name="Path 606" d="M69.24,383.15a4.74,4.74,0,1,0,0,6.7h0A4.75,4.75,0,0,0,69.24,383.15Z" />
      <path id="Path_607-23" data-name="Path 607" d="M102.79,416.62a4.74,4.74,0,1,0,0,6.7h0A4.74,4.74,0,0,0,102.79,416.62Z" />
      <path id="Path_608-23" data-name="Path 608" d="M136.31,416.58a4.74,4.74,0,1,0,0,6.71h0A4.74,4.74,0,0,0,136.31,416.58Z" />
      <path id="Path_609-23" data-name="Path 609" d="M102.75,383.11a4.74,4.74,0,1,0,0,6.7h0a4.74,4.74,0,0,0,0-6.7Z" />
    </g>
    <g id="Group_225-24" data-name="Group 225">
      <path id="Path_604-23" data-name="Path 604" d="M168.77,416.58a4.74,4.74,0,1,0,0,6.7,4.74,4.74,0,0,0,0-6.7Z" />
      <path id="Path_605-24" data-name="Path 605" d="M202.32,450.05a4.74,4.74,0,1,0,0,6.71h0A4.76,4.76,0,0,0,202.32,450.05Z" />
      <path id="Path_606-24" data-name="Path 606" d="M135.29,450.13a4.74,4.74,0,1,0,0,6.7h0A4.73,4.73,0,0,0,135.29,450.13Z" />
      <path id="Path_608-24" data-name="Path 608" d="M202.36,483.57a4.74,4.74,0,1,0,0,6.7h0A4.75,4.75,0,0,0,202.36,483.57Z" />
      <path id="Path_609-24" data-name="Path 609" d="M168.81,450.09a4.74,4.74,0,1,0,0,6.7h0a4.74,4.74,0,0,0,0-6.7Z" />
    </g>
    <g id="Group_225-25" data-name="Group 225">
      <path id="Path_604-24" data-name="Path 604" d="M196.57,483.65a4.74,4.74,0,1,0,6.71,0,4.73,4.73,0,0,0-6.71,0Z" />
      <path id="Path_605-25" data-name="Path 605" d="M230.09,450.13a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,230.09,450.13Z" />
      <path id="Path_607-24" data-name="Path 607" d="M263.6,483.64a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,483.64Z" />
      <path id="Path_608-25" data-name="Path 608" d="M263.6,450.13a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,450.13Z" />
      <path id="Path_609-25" data-name="Path 609" d="M230.08,483.64a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-23" data-name="Path 610" d="M297.12,483.64a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,483.64Z" />
    </g>
    <g id="Group_225-26" data-name="Group 225">
      <path id="Path_604-25" data-name="Path 604" d="M297.16,445.3a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-26" data-name="Path 605" d="M330.67,411.79a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.67,411.79Z" />
      <path id="Path_606-25" data-name="Path 606" d="M330.67,478.81a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.67,478.81Z" />
      <path id="Path_607-25" data-name="Path 607" d="M364.18,445.3a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,364.18,445.3Z" />
      <path id="Path_608-26" data-name="Path 608" d="M364.18,411.79a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,364.18,411.79Z" />
      <path id="Path_609-26" data-name="Path 609" d="M330.67,445.3a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-24" data-name="Path 610" d="M397.7,445.3a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.7,445.3Z" />
    </g>
    <g id="Group_225-27" data-name="Group 225">
      <path id="Path_604-26" data-name="Path 604" d="M364.06,412a4.73,4.73,0,1,0,6.7,0,4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_605-27" data-name="Path 605" d="M397.57,378.45a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.57,378.45Z" />
      <path id="Path_606-26" data-name="Path 606" d="M397.57,445.48a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.57,445.48Z" />
      <path id="Path_607-26" data-name="Path 607" d="M431.08,412a4.74,4.74,0,1,0,6.71,0h0A4.76,4.76,0,0,0,431.08,412Z" />
      <path id="Path_608-27" data-name="Path 608" d="M431.09,378.45a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.09,378.45Z" />
      <path id="Path_609-27" data-name="Path 609" d="M397.57,412a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
    </g>
    <g id="Group_225-28" data-name="Group 225">
      <path id="Path_604-27" data-name="Path 604" d="M397.58,344.93a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-28" data-name="Path 605" d="M431.09,311.42a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.09,311.42Z" />
      <path id="Path_606-27" data-name="Path 606" d="M431.09,378.44a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,431.09,378.44Z" />
      <path id="Path_607-27" data-name="Path 607" d="M464.6,344.92a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,464.6,344.92Z" />
      <path id="Path_608-28" data-name="Path 608" d="M464.6,311.41a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,464.6,311.41Z" />
      <path id="Path_609-28" data-name="Path 609" d="M431.09,344.93a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
    </g>
    <g id="Group_225-29" data-name="Group 225">
      <path id="Path_604-28" data-name="Path 604" d="M431.25,245.26a4.74,4.74,0,1,0,6.71,0,4.73,4.73,0,0,0-6.71,0Z" />
      <path id="Path_605-29" data-name="Path 605" d="M464.77,211.74a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,464.77,211.74Z" />
      <path id="Path_606-28" data-name="Path 606" d="M464.76,278.77a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,464.76,278.77Z" />
      <path id="Path_607-28" data-name="Path 607" d="M498.28,245.25a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,498.28,245.25Z" />
      <path id="Path_608-29" data-name="Path 608" d="M498.28,211.74a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,498.28,211.74Z" />
      <path id="Path_609-29" data-name="Path 609" d="M464.76,245.25a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_611-23" data-name="Path 611" d="M498.28,278.77a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,498.28,278.77Z" />
    </g>
    <g id="Group_225-30" data-name="Group 225">
      <path id="Path_604-29" data-name="Path 604" d="M431.08,177.31a4.73,4.73,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-30" data-name="Path 605" d="M464.59,143.79a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,464.59,143.79Z" />
      <path id="Path_606-29" data-name="Path 606" d="M464.59,210.82a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,464.59,210.82Z" />
      <path id="Path_609-30" data-name="Path 609" d="M464.58,177.31a4.74,4.74,0,1,0,6.71,0h0a4.73,4.73,0,0,0-6.7,0Z" />
    </g>
    <g id="Group_225-31" data-name="Group 225">
      <path id="Path_604-30" data-name="Path 604" d="M364.23,77.63a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-31" data-name="Path 605" d="M397.74,44.12a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.74,44.12Z" />
      <path id="Path_606-30" data-name="Path 606" d="M397.74,111.14a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,397.74,111.14Z" />
      <path id="Path_607-29" data-name="Path 607" d="M431.25,77.63a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,431.25,77.63Z" />
      <path id="Path_609-31" data-name="Path 609" d="M397.74,77.63a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_611-24" data-name="Path 611" d="M431.25,111.15a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,431.25,111.15Z" />
    </g>
    <g id="Group_225-32" data-name="Group 225">
      <path id="Path_604-31" data-name="Path 604" d="M263.59,43.17a4.74,4.74,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-32" data-name="Path 605" d="M297.1,9.66a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.1,9.66Z" />
      <path id="Path_606-31" data-name="Path 606" d="M297.1,76.68a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,297.1,76.68Z" />
      <path id="Path_607-30" data-name="Path 607" d="M330.61,43.17a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,330.61,43.17Z" />
      <path id="Path_608-30" data-name="Path 608" d="M330.62,9.66a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,9.66Z" />
      <path id="Path_609-32" data-name="Path 609" d="M297.1,43.17a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-25" data-name="Path 610" d="M364.13,43.17a4.74,4.74,0,1,0,6.71,0h0A4.74,4.74,0,0,0,364.13,43.17Z" />
      <path id="Path_611-25" data-name="Path 611" d="M330.62,76.69a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,330.62,76.69Z" />
    </g>
    <g id="Group_225-33" data-name="Group 225">
      <path id="Path_604-32" data-name="Path 604" d="M62,76.69a4.73,4.73,0,1,0,6.7,0,4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_605-33" data-name="Path 605" d="M95.55,43.17a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,95.55,43.17Z" />
      <path id="Path_606-32" data-name="Path 606" d="M95.55,110.19a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,95.55,110.19Z" />
      <path id="Path_607-31" data-name="Path 607" d="M129.06,76.68a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,129.06,76.68Z" />
      <path id="Path_608-31" data-name="Path 608" d="M129.06,43.17a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,129.06,43.17Z" />
      <path id="Path_609-33" data-name="Path 609" d="M95.55,76.68a4.74,4.74,0,1,0,6.7,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-26" data-name="Path 610" d="M162.58,76.68a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,162.58,76.68Z" />
      <path id="Path_611-26" data-name="Path 611" d="M129.06,110.2a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,129.06,110.2Z" />
    </g>
    <g id="Group_225-34" data-name="Group 225">
      <path id="Path_604-33" data-name="Path 604" d="M129.05,43.17a4.73,4.73,0,1,0,6.7,0,4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_605-34" data-name="Path 605" d="M162.57,9.65a4.74,4.74,0,1,0,6.7,0h0A4.76,4.76,0,0,0,162.57,9.65Z" />
      <path id="Path_606-33" data-name="Path 606" d="M162.56,76.68a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,162.56,76.68Z" />
      <path id="Path_607-32" data-name="Path 607" d="M196.08,43.16a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,196.08,43.16Z" />
      <path id="Path_608-32" data-name="Path 608" d="M196.08,9.65a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,196.08,9.65Z" />
      <path id="Path_609-34" data-name="Path 609" d="M162.56,43.17a4.74,4.74,0,1,0,6.71,0h0a4.73,4.73,0,0,0-6.7,0Z" />
      <path id="Path_610-27" data-name="Path 610" d="M229.6,43.17a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,229.6,43.17Z" />
      <path id="Path_611-27" data-name="Path 611" d="M196.08,76.68a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,196.08,76.68Z" />
    </g>
    <g id="Group_225-35" data-name="Group 225">
      <path id="Path_604-34" data-name="Path 604" d="M196.57,9.65a4.74,4.74,0,1,0,6.71,0,4.75,4.75,0,0,0-6.71,0Z" />
      <path id="Path_606-34" data-name="Path 606" d="M230.08,43.16a4.74,4.74,0,1,0,6.71,0h0A4.75,4.75,0,0,0,230.08,43.16Z" />
      <path id="Path_607-33" data-name="Path 607" d="M263.6,9.65a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,9.65Z" />
      <path id="Path_609-35" data-name="Path 609" d="M230.08,9.65a4.74,4.74,0,1,0,6.71,0h0a4.74,4.74,0,0,0-6.7,0Z" />
      <path id="Path_610-28" data-name="Path 610" d="M297.12,9.65a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,297.12,9.65Z" />
      <path id="Path_611-28" data-name="Path 611" d="M263.6,43.17a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,263.6,43.17Z" />
    </g>
    <g id="Group_225-36" data-name="Group 225">
      <path id="Path_607-34" data-name="Path 607" d="M28.52,177.3a4.74,4.74,0,1,0,6.7,0h0A4.75,4.75,0,0,0,28.52,177.3Z" />
      <path id="Path_608-33" data-name="Path 608" d="M28.52,143.79a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,28.52,143.79Z" />
      <path id="Path_610-29" data-name="Path 610" d="M62,177.31a4.74,4.74,0,1,0,6.7,0h0A4.73,4.73,0,0,0,62,177.31Z" />
      <path id="Path_611-29" data-name="Path 611" d="M28.52,210.82a4.74,4.74,0,1,0,6.7,0h0A4.74,4.74,0,0,0,28.52,210.82Z" />
    </g>
  </svg>
);

export default SvgServices;
