import React from 'react';
import Link from 'gatsby-plugin-transition-link/AniLink';

import { Navbar, Nav, Container } from 'react-bootstrap';
import SvgLogo from '../SVGs/logo';

const windowGlobal = typeof window !== 'undefined' && window;

const pages = [
  { to: '/projects', title: 'Projects', eventKey: 'projects', button: false },
  { to: '/process', title: 'Process', eventKey: 'process', button: false },
  { to: '/services', title: 'Services', eventKey: 'services', button: false },
  { to: '/about', title: 'About', eventKey: 'about', button: false },
  { to: '/careers', title: 'Careers', eventKey: 'careers', button: false },
  { to: '/getaquote', title: 'get a quote', eventKey: 'getaquote', button: true },
];

const Header = ({ pageInfo }) => {
  React.useEffect(() => {
    let prevScrollpos = windowGlobal.pageYOffset;
    const headerElement = document.getElementById('site-navbar');
    const tlWrapper = windowGlobal && windowGlobal.document && windowGlobal.document.getElementsByClassName('tl-wrapper');
    const tlEdges = windowGlobal && windowGlobal.document && windowGlobal.document.getElementsByClassName('tl-edges');
    windowGlobal.onscroll = () => {
      const currentScrollPos = windowGlobal.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        if (headerElement && headerElement.classList) headerElement.classList.remove('hide-site-navbar');
      } else {
        if (currentScrollPos > 80) {
          if (tlEdges && tlEdges.length && tlEdges[0].className && !tlEdges[0].className.includes('overflow-visible')) {
            tlEdges[0].classList.add('overflow-visible');
          }
          if (tlWrapper && tlWrapper.length && tlWrapper[0].style) {
            const x = tlWrapper[0].style.transform;
            if (x) tlWrapper[0].style.transform = '';
          }
          if (headerElement && headerElement.classList) headerElement.classList.add('hide-site-navbar');
        }
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  const toggleNavbar = (showing) => {
    if (showing) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  };

  return (
    <>
      <Navbar onToggle={(showing) => toggleNavbar(showing)} fixed="top" variant="light" expand="lg" id="site-navbar">
        <Container>
          <button
            onClick={() => toggleNavbar(false)}
            type="button"
            style={{
              background: 'none',

              boxShadow: 'none',

              border: 0,
            }}
          >
            <Link paintDrip hex="#000000" to="/" className="link-no-style">
              <Navbar.Brand as="span" style={{ display: 'inline-flex' }}>
                <SvgLogo />
              </Navbar.Brand>
            </Link>
          </button>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            as="button"
            onClick={() => {
              document.getElementById('hamburger-1').classList.toggle('is-active');
            }}
          >
            <div className="hamburger" id="hamburger-1">
              <span className="hamburger-line" />
              <span className="hamburger-line" />
              <span className="hamburger-line" />
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            <Nav activeKey={pageInfo && pageInfo.pageName}>
              {pages.map((item) => (
                <Link paintDrip hex={item.button ? '#003bff' : '#000000'} key={item.eventKey} to={item.to} className="link-no-style p-0 pl-md-1 pl-lg-3 pl-xl-5">
                  <Nav.Link onSelect={() => toggleNavbar(false)} as="span" eventKey={item.eventKey} className={`${item.button ? 'custom-button' : ''} ${!item.button && pageInfo && pageInfo.pageName === item.eventKey ? 'text-primary' : 'text-dark'}`}>
                    {item.title}
                  </Nav.Link>
                </Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
